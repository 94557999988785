export const login = "/login";
// 获取个人信息
export const userInfo="/users/info"
// 获取菜单
export const getMenuListByRoleId = "/rules/menus";
// 角色管理
export const getRoleList = "/rules";
// 获取部门
export const organList='/organizations'
// 管理员管理
export const getManagers = "/managers";  
// 获取用户钱包
export const getWallet = "/users/wallet";
// 更改银行信息
export const changeBank = "/users/wallet/bank";
// 更改瑞讯信息
export const changeRuiXun = "/users/wallet/swissquote";
// 返佣设置
export const getCommission = "/systems/commission";
// 代理列表
export const agentList='/proxies'
// 获取代理名称列表
export const agentNameList='/proxies/names'
// 获取返佣标准列表
export const getcomList = "/proxies/commission_standards";
// 更改密码
export const changePassword = "/users/password";
// 客户列表
export const userList = "/customers";
// 阿里云sts授权
export const stsUrl = "/sts";
// 获取临时访问链接
export const getImg = "/temporary-url";
// 返佣更新记录
export const commissionRecord = "/users/commission_standard/logs";
// 获取交易记录
export const getTradeRecord = "/orders/history";
// 获取返佣记录
export const getCommissionRecord = "/commissions/logs";
// 获取提现记录
export const getWithdrawRecord = "/withdraws/proxy";
// 财务获取提现列表
export const getWithdrawList = "/withdraws/manage";
// 获取审核状态
export const getWithdrawStatus = "/withdraws/status";
// 获取推广链接
export const getPromotion = "/customers/reg-url";
// 获取预警列表
export const getWarningList = "/funds/monitoring";
// 设置劣后百分比
export const setWarning = "/funds/advance-funds-percent";
// 交易统计
export const getTradeStatistics = "/orders/history/statistics";









